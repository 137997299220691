//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "topics/getField",
  mutationType: "topics/updateField",
});
export default {
  name: "TopicProfile",
  props: {},
  emits: ['card-click'],
  data() {
    return {
      navigation: false,
      confirm: false
    };
  },
  computed: {
    ...mapFields({
      title: "topicForm.title",
      categoryId: "topicForm.categoryId",
      categoriesTagged: "topicForm.categoriesTagged",
      userId: "topicForm.userId",
      createdAt: "topicForm.createdAt",
      positiveSupports: "topicForm.positiveSupports",
      negativeSupports: "topicForm.negativeSupports",
      numberOfReplies: "topicForm.numberOfReplies",
      content: "topicForm.content",
      views: "topicForm.views",
    }),
    pagination() {
      return this.$q.screen.width < 768 ? false : true;
    } 
  },
  methods: {
    mask(text){
      const limit = 20;
      if (text.length>limit) return text.substring(0, limit)+'...';
      return text;
    },
    async emitThisTopic(topic) {
      this.$router.push({
        name: "TopicPage",
        params: { topicId: topic.id },
      });
    },
    removeTopic() {
      this.$store.dispatch("topics/deleteTopic", { $socket: this.$socket });
    }
  },
};
