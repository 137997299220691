var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-profile"},[_c('carousel',{staticClass:"carousel",attrs:{"scroll-per-page":true,"per-page":1,"per-page-custom":[
      [640, 2],
      [1366, 3],
      [1600, 5]
    ],"pagination-enabled":_vm.pagination}},_vm._l((_vm.$store.getters['events/getMyEvents']),function(event){return _c('slide',{key:event.id},[_c('div',{staticClass:"content",style:({
          background:
            event.imageIds.length > 0
              ? ("url(" + (_vm.$store.getters['services/getImagePath']) + (event.imageIds[0]) + ") no-repeat")
              : _vm.$store.getters['categories/getCategoryById'](event.categoryId)
                .color,
          'background-size': 'cover',
        })},[_c('q-fab',{staticClass:"btn-actions no-border-radius",attrs:{"square":"","color":"black","padding":"13px","text-color":"white","icon":"more_vert","direction":"left"}},[_c('q-fab-action',{staticClass:"no-border-radius",attrs:{"square":"","color":"black","text-color":"white","icon":"visibility"},on:{"click":function($event){return _vm.openEvent(event.id)}}}),_c('q-fab-action',{staticClass:"no-border-radius",attrs:{"square":"","color":"black","text-color":"white","icon":"delete"},on:{"click":function($event){_vm.$store.commit('events/SET_CURRENT_EVENT', event),
              (_vm.confirm = true)}}}),_c('q-fab-action',{staticClass:"no-border-radius",attrs:{"square":"","color":"black","text-color":"white","icon":"edit"},on:{"click":function($event){_vm.$router.push(("/profile/events/edit/" + (event.id))),
              _vm.$store.commit('events/SET_CURRENT_EVENT', event)}}})],1),_c('div',{staticClass:"absolute-bottom custom-caption"},[_c('div',{staticClass:"text-h2"},[_vm._v(" "+_vm._s(_vm.mask(event.title))+" ")]),_c('div',{staticClass:"text-subtitle1"},[_vm._v(" "+_vm._s(event.local)+" ")])]),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}},[_c('q-card',[_c('q-card-section',{staticClass:"row items-center"},[_c('q-avatar',{attrs:{"icon":"fas fa-trash","color":"negative","text-color":"white"}}),_c('span',{staticClass:"q-ml-sm"},[_vm._v("Tem certeza que deseja remover esse evento?")])],1),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"flat":"","label":"Cancelar","color":"negative"}}),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"flat":"","label":"Remover","color":"negative"},on:{"click":function($event){return _vm.removeEvent()}}})],1)],1)],1)],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }