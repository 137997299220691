//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PinsProfile from '../../components/PinsProfile.vue';
import TopicsProfile from '../../components/TopicsProfile.vue';
import EventsProfile from '../../components/EventsProfile.vue';

export default {
  name: "Sliders",
  components: {
    PinsProfile,
    TopicsProfile,
    EventsProfile
  },
  data () {
    return {
      topicSlider: 1,
      eventSlider: 1,
    }
  },
  computed: {
   pins() {
     return this.$store.getters['pins/getMyPins'].length > 0;
   },
   events() {
     return this.$store.getters['events/getMyEvents'].length > 0;
   },
   topics() {
     return this.$store.getters['topics/getMyTopics'].length > 0;
   }

  }
}
