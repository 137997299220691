//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "events/getField",
  mutationType: "events/updateField",
});
export default {
  name: "EventsProfile",
  props: {},
  emits: ["card-click"],
  data() {
    return {
      navigation: false,
      confirm: false,
    };
  },
  computed: {
    ...mapFields({
      category: "categorySelected",
      categoryId: "eventForm.categoryId",
      imageIds: "eventForm.imageIds",
      title: "eventForm.title",
      date: "eventForm.date",
      time: "eventForm.time",
      street: "eventForm.street",
      neighborhood: "eventForm.neighborhood",
      number: "eventForm.number",
      zipcode: "eventForm.zipcode",
      city: "eventForm.city",
      ticket: "eventForm.ticket",
      link: "eventForm.link",
      local: "eventForm.local",
      description: "eventForm.description",
    }),
    pagination() {
      return this.$q.screen.width < 768 ? false : true;
    } 
  },
  methods: {
    mask(text) {
      const limit = 20;
      if (text.length > limit) return text.substring(0, limit) + "...";
      return text;
    },
    async openEvent(eventId) {
      this.$router.push({
        name: "Agenda",
        hash: `#${eventId}`,
      });
    },

    removeEvent() {
      this.$store.dispatch("events/deleteEvent");
    },
  },
};
